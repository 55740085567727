<template>
    <div class="app-container">
        <div id="problemsContainer">
            <Box :type="'error'" :active="true" v-if="inputProblems.length > 0">
                <div slot="box-body">
                    <span class="title-no-border">
                        <strong>{{ $t('products.product.box1') }}:</strong>
                    </span>
                    <ul class="rawUl">
                        <li v-for="(problem, index) in inputProblems" :key="index">
                            <span v-html="problem"></span>
                        </li>
                    </ul>
                </div>
            </Box>
        </div>
        <Box :type="'info'" :active="true">
            <div slot="box-body">
                <ul class="rawUl">
                    <li>
                        <strong>{{ $t('products.product.box2') }}</strong>
                    </li>
                </ul>
            </div>
        </Box>
        <Box :type="'warning'" :active="true" v-if="!product.can_edit">
            <div slot="box-body">
                <ul class="rawUl">
                    <li>
                        <strong>{{ $t('products.product.warning_detinator_documentatie') }}</strong>
                    </li>
                </ul>
            </div>
        </Box>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $t('products.product.edit-product') }}</span>
                <span style="float: right">
                    <el-button
                        type="primary"
                        size="mini"
                        icon="el-icon-check"
                        plain
                        @click.native="updateStockAndPrice"
                        >{{ $t('products.product.updatestockandprice') }}</el-button
                    >
                    <el-button
                        :disabled="!product.can_edit"
                        type="primary"
                        size="mini"
                        icon="el-icon-check"
                        plain
                        @click.native="saveProduct"
                        >{{ $t('products.product.save') }}</el-button
                    >
                </span>
            </div>
            <div class="productDetails">
                <div class="title">
                    {{ $t('products.product.details') }}
                </div>
                <template v-if="product.can_edit">
                    <div>
                        <div class="sub-title">{{ $t('products.product.category') }}</div>

                        <c-select
                            @selected="categorySelected"
                            v-if="typeof product.categories_id !== 'undefined'"
                            :categ="product.categories_id"
                        ></c-select>

                        <!-- <el-cascader change-on-select filterable expand-trigger="click" clearable :placeholder="$t('products.product.ph-1')"
                        class="fullWidth" :options="categories" v-model="product.category" @change="handleCategoryChange">
                        </el-cascader> -->
                    </div>
                </template>
                <template v-else>
                    <div class="sub-title">{{ $t('products.product.category') }}</div>
                    <Box :type="'warning'" :active="true">
                        <div slot="box-body">
                            <p>{{ $t('products.product.info_detinator_documentatie') }}</p>
                        </div>
                    </Box>
                </template>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.product.product-name') }}</div>
                    <el-input
                        :disabled="!product.can_edit"
                        show-word-limit
                        :maxlength="characterLimits.productName"
                        placeholder="Ex: Telefon Mobil Samsung Galaxy S8 G950F 64GB 4G Black"
                        v-model="product.products_name"
                    ></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.product.man') }}</div>
                    <el-input
                        placeholder="Ex: Samsung"
                        v-model="product.manufacturer"
                        :disabled="!product.can_edit"
                    ></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.product.products-model') }}</div>
                    <el-input placeholder="Ex: SM-G950FZKAROM" v-model="product.products_model" disabled></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.product.intern-id') }}</div>
                    <el-input
                        placeholder="Ex: 123456"
                        v-model="product.vendor_ext_id"
                        :disabled="!product.can_edit"
                    ></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.product.price') }}</div>
                    <el-input placeholder="Ex: 101.3" v-model="product.pretf"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.product.old-price') }}</div>
                    <el-input placeholder="Ex: 129" v-model="product.pret_vechi"></el-input>
                </div>
                <div class="separator"></div>
                <div v-if="!product.products_tax_class_id">
                    <div class="sub-title">{{ $t('products.product.dimensiuni_cm') }}</div>
                    <el-row :gutter="20">
                        <el-col :xs="24" :md="8">
                            <div class="sub-title">{{ $t('products.product.dimensiuni_latime') }}</div>
                            <el-input-number
                                :controls="false"
                                class="delinput"
                                placeholder="Ex: 21"
                                v-model="product.dimensions.latime"
                            />
                        </el-col>
                        <el-col :xs="24" :md="8">
                            <div class="sub-title">{{ $t('products.product.dimensiuni_inaltime') }}</div>
                            <el-input-number
                                placeholder="Ex: 11"
                                :controls="false"
                                class="delinput"
                                v-model="product.dimensions.inaltime"
                            />
                        </el-col>
                        <el-col :xs="24" :md="8">
                            <div class="sub-title">{{ $t('products.product.dimensiuni_adancime') }}</div>
                            <el-input-number
                                placeholder="Ex: 5"
                                :controls="false"
                                class="delinput"
                                v-model="product.dimensions.adancime"
                            />
                        </el-col>
                    </el-row>
                    <template
                        v-if="
                            !dimensiuniProdusValide(product.dimensions) &&
                            dimensiuniProdusValide(product.cat_dimensions)
                        "
                    >
                        <el-alert type="info" effect="dark" :closable="false"
                            ><strong
                                >{{ $t('products.product.info_dimensiuni_categorie') }}
                                {{ product.cat_dimensions.latime }} x {{ product.cat_dimensions.inaltime }} x
                                {{ product.cat_dimensions.adancime }}</strong
                            ></el-alert
                        >
                    </template>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.product.warranty-pf') }}</div>
                    <el-input placeholder="Ex: 24" v-model="product.garantie" :disabled="!product.can_edit"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.product.warranty-pj') }}</div>
                    <el-input
                        placeholder="Ex: 12"
                        v-model="product.garantie_pj"
                        :disabled="!product.can_edit"
                    ></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.product.stock') }}</div>
                    <el-input placeholder="Ex: 10" v-model="product.stoc"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">Status</div>
                    <el-select v-model="product.status" placeholder="Select" class="fullWidth">
                        <el-option :label="$t('products.product.active')" value="1"> </el-option>
                        <el-option :label="$t('products.product.inactive')" value="0"> </el-option>
                    </el-select>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.product.process-time') }}</div>
                    <el-input :placeholder="$t('products.product.process-range')" v-model="product.procesare_in">
                    </el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.product.weight') }} (Kg)</div>
                    <el-input
                        :placeholder="$t('products.product.weight')"
                        v-model="product.real_weight"
                        :disabled="!product.can_edit"
                    ></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">
                        {{ $t('products.product.virtual') }} {{ $t('products.product.virtual_extra') }}
                    </div>
                    <el-select
                        v-model="product.products_tax_class_id"
                        placeholder="Select"
                        class="fullWidth"
                        :disabled="!product.can_edit"
                    >
                        <el-option :disabled="!product.can_edit" :label="$t('products.product.is_virtual')" :value="1">
                        </el-option>
                        <el-option
                            :disabled="!product.can_edit"
                            :label="$t('products.product.is_not_virtual')"
                            :value="0"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="separator"></div>
            <div class="productFamily">
                <div class="title">
                    {{ $t('products.product.family') }}
                </div>
                <template v-if="product.can_edit">
                    <template v-if="allCharacteristics.length > 0">
                        <!--					<div>-->
                        <!--						<div class="sub-title">{{$t('products.product.family-id')}}</div>-->
                        <!--						<el-input :placeholder="$t('products.product.family-id')" v-model="product.family_id">-->
                        <!--						</el-input>-->
                        <!--					</div>-->
                        <!--					<div class="separator"></div>-->
                        <!--					<div>-->
                        <!--						<div class="sub-title">{{$t('products.product.family-name')}}</div>-->
                        <!--						<el-input :placeholder="$t('products.product.family-name')" v-model="product.family_name">-->
                        <!--						</el-input>-->
                        <!--					</div>-->
                        <div>
                            <el-select v-model="product.family_id" @change="onFamilySelect">
                                <el-option
                                    v-for="family in families"
                                    :key="family.id"
                                    :value="family.id"
                                    :label="family.name"
                                >
                                </el-option>
                            </el-select>
                            <el-button
                                v-if="product.family_id != null && product.family_id > 0"
                                type="danger"
                                @click="removeProductFamily"
                                >Scoate produsul din familia curenta
                            </el-button>
                            <el-button type="primary" @click="getFamilies">Reincarca lista familii </el-button>
                            <el-button type="success" @click="addNewFamily">Adauga familie noua </el-button>
                        </div>
                        <div class="separator"></div>
                        <div class="sub-title" v-if="product.family_id != null && product.family_id > 0">
                            <strong>{{ $t('products.product.family-charact') }}</strong>
                            <el-button type="primary" icon="el-icon-plus" size="mini" @click="addFamilyCharact" plain>
                            </el-button>
                        </div>
                        <el-card
                            shadow="never"
                            class="product-family-card"
                            v-for="(item, i) in familyCharacts"
                            :key="i"
                        >
                            <div slot="header" class="clearfix">
                                <span class="sub-title">Tip #{{ i + 1 }}</span>
                                <el-button
                                    style="float: right"
                                    size="small"
                                    type="text"
                                    @click="removeFamilyCharact(i)"
                                >
                                    {{ $t('general.delete') }}</el-button
                                >
                            </div>
                            <div>
                                <div class="sub-title">{{ $t('products.product.family-type') }}</div>
                                <el-select
                                    size="small"
                                    filterable
                                    v-model="familyCharacts[i].charact"
                                    :placeholder="$t('products.product.family-type')"
                                    :no-data-text="$t('products.product.no-data-text')"
                                    class="fullWidth"
                                >
                                    <el-option
                                        v-for="item in familyTypeOptions"
                                        :label="item.label"
                                        :value="item.value"
                                        :key="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="separator"></div>
                            <div>
                                <div class="sub-title">{{ $t('products.product.family-mode') }}</div>
                                <el-select
                                    size="small"
                                    v-model="familyCharacts[i].mode"
                                    :placeholder="$t('products.product.family-mode')"
                                    class="fullWidth"
                                >
                                    <el-option
                                        v-for="item in familyModeOptions"
                                        :label="item.label"
                                        :value="item.value"
                                        :key="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="separator"></div>
                        </el-card>
                    </template>
                    <template v-else>
                        <div class="sub-title">
                            <strong>{{ $t('products.product.select-category') }}</strong>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <Box :type="'warning'" :active="true">
                        <div slot="box-body">
                            <p>{{ $t('products.product.info_detinator_documentatie') }}</p>
                        </div>
                    </Box>
                </template>
            </div>
            <div class="separator"></div>
            <div class="productCharacts">
                <div class="title">
                    {{ $t('products.product.characts') }}
                </div>
                <template v-if="product.can_edit">
                    <div
                        v-if="
                            typeof product.category !== 'undefined' &&
                            product.category > 0 &&
                            allCharacteristics.length > 0
                        "
                    >
                        <div v-for="(charact, x) in allCharacteristics" :key="x">
                            <div class="sub-title">
                                {{ charact.charact_name }}
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="$t('products.product.tooltip-1')"
                                    placement="top-start"
                                >
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        icon="el-icon-plus"
                                        plain
                                        @click.native="updateCount(x)"
                                    ></el-button>
                                </el-tooltip>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="$t('products.product.tooltip-2')"
                                    placement="top-start"
                                >
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        icon="el-icon-minus"
                                        plain
                                        @click.native="removeCharactValue(x)"
                                    ></el-button>
                                </el-tooltip>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="$t('products.product.tooltip-3')"
                                    placement="top-start"
                                    v-if="parseInt(charact.restrictions) !== 1 && charact.charact_value !== null"
                                >
                                    <el-button
                                        style="margin-left: 0"
                                        type="warning"
                                        size="mini"
                                        icon="el-icon-tickets"
                                        plain
                                        @click.native="openModal(charact.charact_id, charact.restrictions)"
                                    ></el-button>
                                </el-tooltip>
                            </div>
                            <div v-if="charact.charact_value === null">
                                <el-input
                                    v-for="i in charact.count"
                                    :key="i"
                                    @change="setCharactVal(i, charact['selected_' + i], charact)"
                                    show-word-limit
                                    :placeholder="setPlaceholder(charact.priority)"
                                    :class="checkCharactPriority(charact.charact_id, charact.priority)"
                                    class="margin-bottom-10"
                                    v-model="charact['selected_' + i]"
                                    :maxlength="characterLimits.characteristics"
                                ></el-input>
                            </div>
                            <div v-else>
                                <span v-for="i in charact.count" :key="i">
                                    <el-select
                                        filterable
                                        v-model="charact['selected_' + i]"
                                        @change="setCharactVal(i, charact['selected_' + i], charact)"
                                        class="fullWidth margin-bottom-10"
                                        clearable
                                        :placeholder="setPlaceholder(charact.priority)"
                                        :class="checkCharactPriority(charact.charact_id, charact.priority)"
                                    >
                                        <el-option v-for="val in charact.charact_value" :value="val" :key="val">
                                        </el-option>
                                    </el-select>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-else class="sub-title">
                        <strong>{{ $t('products.product.select-category-2') }}</strong>
                    </div>
                </template>
                <template v-else>
                    <Box :type="'warning'" :active="true">
                        <div slot="box-body">
                            <p>{{ $t('products.product.info_detinator_documentatie') }}</p>
                        </div>
                    </Box>
                </template>
            </div>
            <div class="separator"></div>
            <div class="productDescription">
                <div class="title">
                    {{ $t('products.product.desc') }}
                </div>
                <template v-if="product.can_edit">
                    <Box :type="'info'" :active="true">
                        <div slot="box-body">
                            <ul class="rawUl">
                                <li>Evitati linkurile externe in descriere. Acestea pot fi eliminate automat.</li>
                                <li>Tipuri de imagini acceptate: JPG, PNG, GIF.</li>
                            </ul>
                        </div>
                    </Box>
                    <Editor :text.sync="product.products_description"></Editor>
                </template>
                <template v-else>
                    <Box :type="'warning'" :active="true">
                        <div slot="box-body">
                            <p>{{ $t('products.product.info_detinator_documentatie') }}</p>
                        </div>
                    </Box>
                </template>
            </div>
            <!-- <div class="separator"></div>
        <div class="productImages">
            <div class="title">
                Imagini
            </div>
            <div>
                <div class="sub-title">Imagine principala</div>
            </div>
            <el-upload id="mainImageId" list-type="picture" :auto-upload='false' :on-remove="removeMainImage" class="upload-demo" :limit="1" accept="image/*" action="https://jsonplaceholder.typicode.com/posts/">
                <el-button size="small" type="primary">Incarca imagine principala</el-button>
            </el-upload>
            <div class="separator"></div>

            <div>
                <div class="sub-title">Imagini secundare</div>
                <dropzone ref="dropzoneComp" id="myVueDropzone" url="https://httpbin.org/" v-bind:use-custom-dropzone-options="true" v-bind:dropzone-options="dropzoneOptions">
                    <input type="hidden" name="token" value="xxx">
                </dropzone>
            </div>
        </div> -->
            <div class="separator"></div>
            <div class="productImages">
                <div class="title">
                    {{ $t('products.product.images') }}
                </div>
                <template v-if="product.can_edit">
                    <Box :type="'info'" :active="true">
                        <div slot="box-body">
                            <ul class="rawUl">
                                <li>Tipuri de imagini acceptate: JPG, PNG, GIF.</li>
                                <li>Dimensiune maxima acceptata: 1200x1200px</li>
                                <li>Dimensiune minima acceptata: 200x200px</li>
                                <li>Imaginile vor fi reprocesate automat si vor fi transformate in format JPG.</li>
                            </ul>
                        </div>
                    </Box>
                    <div class="mainImage">
                        <div class="sub-title">
                            {{ $t('products.product.main-image') }}
                        </div>
                        <img :src="mainImageLink" alt="" />
                        <el-input v-model="product.products_image" size="mini" class="input-with-select secInput">
                            <el-upload
                                :id="`primary_upload`"
                                accept="image/*"
                                size="mini"
                                slot="append"
                                :auto-upload="false"
                                :show-file-list="false"
                                action="https://jsonplaceholder.typicode.com/posts/"
                                :on-change="replacePrincipalImage"
                            >
                                <el-button slot="trigger" type="primary"><i class="el-icon-upload"></i></el-button>
                            </el-upload>
                        </el-input>
                    </div>

                    <div class="secImages">
                        <div class="sub-title">
                            {{ $t('products.product.sec-image') }}
                        </div>
                        <div class="secImage" v-for="(img, i) in getSecondaryImages" :key="i">
                            <img :src="img.url" alt="" />
                            <el-input v-model="secondaryImages[i].url" size="mini" class="input-with-select secInput">
                                <el-button
                                    v-if="typeof img.url !== 'undefined' && img.url !== ''"
                                    slot="prepend"
                                    icon="el-icon-picture"
                                    :title="$t('products.product.set-main')"
                                    @click="setPrimaryImage(i)"
                                ></el-button>
                                <el-upload
                                    :id="`file_${i}`"
                                    accept="image/*"
                                    size="mini"
                                    slot="append"
                                    :auto-upload="false"
                                    :show-file-list="false"
                                    action="https://jsonplaceholder.typicode.com/posts/"
                                    :on-change="replaceImage"
                                >
                                    <el-button slot="trigger" type="primary" @click="setID(i)"
                                        ><i class="el-icon-upload"></i
                                    ></el-button>
                                </el-upload>
                            </el-input>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <Box :type="'warning'" :active="true">
                        <div slot="box-body">
                            <p>{{ $t('products.product.info_detinator_documentatie') }}</p>
                        </div>
                    </Box>
                </template>
            </div>
        </el-card>
        <el-card class="box-card" v-if="product.can_edit">
            <el-button type="primary" style="width: 100%" icon="el-icon-check" plain @click.native="saveProduct">{{
                $t('products.product.save')
            }}</el-button>
        </el-card>

        <el-dialog :title="$t('products.product.add-value')" :visible.sync="dialogVisible" class="dialog-xs">
            <span>
                <div class="sub-title">{{ $t('products.product.value') }}</div>
                <el-input v-model="newValueInput"></el-input>
            </span>

            <Box v-if="modalCharactRestriction == 2" :type="'info'" :active="true" style="margin: 10px 0">
                <div slot="box-body">
                    <ul class="rawUl">
                        <li>Valorile noi necesita aprobare.</li>
                        <li>Valorile noi vor fi asociate produsului dupa aprobare.</li>
                    </ul>
                </div>
            </Box>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{ $t('general.cancel') }}</el-button>
                <el-button type="primary" @click="addNewCharactVal">{{ $t('general.add') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
const Box = () => import('vue-info-box-element');
const Editor = () => import('@/components/Editor');
const CategorySelect = () => import('@/components/CategoriesSelect');
import { getCategCharacts } from '@/api/publicare';
import {
    getProducts,
    updateProduct,
    ajaxUploadImage,
    getProductsFamilyForCategory,
    updateStockAndPrice,
} from '@/api/produse';
import { charactPriority, setParams, debugPromise } from '@/utils/index';
import { EventBus } from '@/utils/Bus.js';
import size from 'lodash.size';
import isNull from 'lodash.isnull';
import has from 'lodash.has';
import isEmpty from 'lodash.isempty';
import isUndefined from 'lodash.isundefined';

export default {
    components: {
        Box,
        Editor,
        'c-select': CategorySelect,
    },
    props: ['pid', 'vendorId'],
    data() {
        return {
            productsModel: '',
            vendorExtId: '',
            categories: [],
            test: {},
            product: {
                category: [],
                title: '',
                brand: '',
                model: '',
                description: '',
                price: '',
                stoc: '',
                characteristics: {},
                dimensions: {},
                oldPrice: '',
                status: '1',
                family_id: '',
                family_name: '',
                family_type: '',
                family_mode: 1,
                real_weight: 0,
            },
            rawCategories: [],
            secondaryImages: [],
            allCharacteristics: [],
            requiredCharacteristics: [],
            dialogVisible: false,
            newValueInput: '',
            modalCharactId: '',
            modalCharactRestriction: '',
            inputProblems: [],
            oldValues: [],
            secondaryImagesPrefix: 'https://s1.cel.ro/images/mari/',
            familyModeOptions: [
                {
                    value: 1,
                    label: 'Text',
                },
                {
                    value: 2,
                    label: this.$t('products.product.image'),
                },
            ],
            familyCharacts: [
                // 	{
                // 	charact: '',
                // 	type: 1
                // }
            ],
            idSecondaryImages: 0,
            families: [],
            chosenFamily: '',
        };
    },
    methods: {
        dimensiuniProdusValide(dimensiuni) {
            if (
                typeof dimensiuni != 'undefined' &&
                typeof dimensiuni.adancime != 'undefined' &&
                dimensiuni.adancime > 0 &&
                typeof dimensiuni.inaltime != 'undefined' &&
                dimensiuni.inaltime > 0 &&
                typeof dimensiuni.latime != 'undefined' &&
                dimensiuni.latime > 0
            ) {
                return true;
            }

            return false;
        },
        removeCharactValue(indexAll) {
            const indexVal = this.allCharacteristics[indexAll].count;

            const charactName = this.allCharacteristics[indexAll].charact_name;

            delete this.product.charact[charactName][this.product.charact[charactName].length - 1];

            if ('undefined' != this.allCharacteristics[indexAll]['selected_' + indexVal]) {
                delete this.allCharacteristics[indexAll]['selected_' + indexVal];
            }

            if (this.allCharacteristics[indexAll].count > 0) {
                const count = this.allCharacteristics[indexAll].count - 1;
                this.$set(this.allCharacteristics[indexAll], 'count', count);
            }
            this.$forceUpdate();
        },
        updateCount(index) {
            const count = this.allCharacteristics[index].count + 1;
            this.$set(this.allCharacteristics[index], 'count', count);
            this.$forceUpdate();
        },
        updateStockAndPrice() {
            this.inputProblems = [];
            const params = {
                pretf: this.product.pretf,
                stoc: this.product.stoc,
                pret_vechi: this.product.pret_vechi,
                procesare_in: this.product.procesare_in,
                status: this.product.status,
                products_model: this.product.products_model,
            };
            updateStockAndPrice({
                products: [params],
            }).then((res) => {
                if (res == null || res.error == null || res.error == 1) return false;
                if (res.message.bad_list.length > 0) {
                    for (const problem of res.message.bad_list) {
                        this.inputProblems.push(decodeURIComponent(problem.msg.replace(/\+/g, ' ')));
                    }
                }
                if (res.message.updated > 0) {
                    if (res.message.updated < params.length) {
                        this.sbMsg({
                            type: 'warn',
                            message: this.$t('products.product.error-update'),
                        });
                    } else {
                        this.sbMsg({
                            type: 'success',
                            message: 'Produsul a fost actualizat',
                        });
                    }
                } else {
                    this.sbMsg({
                        type: 'warn',
                        message: this.$t('products.product.error-update'),
                    });
                    return false;
                }
            });
        },
        saveProduct() {
            if (!this.validateData()) return false;
            let characts = {};
            for (const x in this.product.charact) {
                let isAtleastOne = false;
                for (const y in this.product.charact[x]) {
                    if (size(this.product.charact[x][y]) && !isNull(this.product.charact[x][y])) isAtleastOne = true;
                }
                if (isAtleastOne) {
                    characts[x] = this.product.charact[x];
                }
            }
            characts = Object.assign({}, characts);

            let params = {
                category: this.product.category,
                products_name: this.product.products_name,
                manufacturer: this.product.manufacturer,
                products_model: this.product.products_model,
                vendor_ext_id: this.product.vendor_ext_id != null ? this.product.vendor_ext_id : '',
                products_description: this.product.products_description,
                products_dimensions: this.product.dimensions,
                characteristics: characts,
                pretf: this.product.pretf,
                stoc: this.product.stoc,
                // 'products_weight': this.product.type,
                pret_vechi: this.product.pret_vechi,
                status: this.product.status,
                garantie: this.product.garantie,
                garantie_pj: this.product.garantie_pj,
                procesare_in: this.product.procesare_in,
                image: this.secondaryImages,
                products_image: this.product.products_image,
                bypassProductsFur: 1,
                real_weight: this.product.real_weight,
                virtual: this.product.products_tax_class_id,
            };

            if (has(this.product, 'family_id') && this.product.family_id !== '') {
                params.family_id = this.product.family_id;
                params.family_name = this.product.family_name;
                params.family_type = this.familyCharacts;
            }

            params = this.trimParams(params);
            const route = 'undefined' == typeof params.type;

            params = setParams(params);
            // let secondaryImages = this.$refs.dropzoneComp.getAcceptedFiles()
            // for (var i in secondaryImages) {n
            //     params.append('file_' + i, secondaryImages[i]);
            // }
            // params.append('mainImage', document.getElementById('mainImageId').querySelector('.el-upload__input').files[0]);

            updateProduct(params)
                .then((res) => {
                    if (res == null) throw new Error(this.$t('products.product.error-update'));
                    if (parseInt(res.error) > 0) {
                        if (res.message.failed.length > 0) {
                            if (res.message.failed[0].columns[0].msg != '') {
                                this.inputProblems.push(
                                    decodeURIComponent(res.message.failed[0].columns[0].msg.replace(/\+/g, ' '))
                                );
                            } else if (res.message.failed[0].columns[0].column != '') {
                                this.inputProblems.push(
                                    decodeURIComponent(res.message.failed[0].columns[0].column.replace(/\+/g, ' '))
                                );
                            }
                            this.sbMsg({
                                type: 'error',
                                message: this.$t('products.product.error-update'),
                            });
                            return false;
                        } else {
                            this.sbMsg({
                                type: 'warn',
                                message: this.$t('products.product.error-update-2'),
                            });
                            return false;
                        }
                    }
                    if (!isEmpty(res.message)) {
                        if (route) {
                            this.sbMsg({
                                type: 'success',
                                message: this.$t('products.product.update-success'),
                            });
                            this.$router.push('/produse/in-asteptare').catch(() => {});
                        } else {
                            this.sbMsg({
                                type: 'success',
                                message: this.$t('products.product.update-success-2'),
                            });
                        }
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'updateProduct',
                            params,
                        },
                    });
                    debugPromise('updateProduct', e);
                });
        },
        trimParams(params) {
            let localParams = this.clone(params);

            for (const e in localParams) {
                // Bypass exception - ignore
                if (e == 'bypassProductsFur') {
                    delete localParams['bypassProductsFur'];
                    continue;
                }
                // Characteristics exception - array
                if (e == 'characteristics') {
                    for (const c in localParams.characteristics) {
                        if ('undefined' == typeof this.oldValues.charact[c]) continue;

                        for (const i in this.oldValues.charact[c]) {
                            if ('undefined' == typeof localParams.characteristics[c][i]) return params;

                            if (localParams.characteristics[c][i] == this.oldValues.charact[c][i]) {
                                delete localParams['characteristics'][c][i];
                            }
                        }
                        localParams.characteristics[c] = this.cleanArray(localParams.characteristics[c]);
                        if (Object.keys(localParams.characteristics[c]).length == 0) {
                            delete localParams.characteristics[c];
                        }
                    }
                    localParams.characteristics = this.cleanArray(localParams.characteristics);
                    if (Object.keys(localParams.characteristics).length == 0) delete localParams['characteristics'];

                    continue;
                }
                // Secondary images exception - array
                // if (e == "image") {
                // 	for (var c in localParams.image) {
                // 		if (typeof localParams.image[c].url === 'undefined' || localParams.image[c].url == "") {
                // 			delete localParams["image"][c]
                // 			continue
                // 		}

                // 		let shortName = localParams["image"][c].url.substr(this.secondaryImagesPrefix.length);

                // 		if ("undefined" == typeof this.oldValues.secondary_images[c]) continue;

                // 		if (shortName == this.oldValues.secondary_images[c]) delete localParams["image"][c]
                // 	}
                // 	localParams.image = this.cleanArray(localParams.image);
                // 	if (localParams.image.length == 0) delete localParams["image"]

                // 	continue
                // }
                // Category exception
                if (e == 'category') {
                    delete this.oldValues.category;
                    this.oldValues = this.cleanArray(this.oldValues);
                    if (this.oldValues.categories_id == localParams.category) delete localParams['category'];
                    continue;
                }

                // Regular element
                if ('undefined' == typeof this.oldValues[e]) continue;
                if (localParams[e] == this.oldValues[e]) delete localParams[e];
            }

            localParams = this.cleanArray(localParams);
            const updateFields = ['products_model', 'stoc', 'pretf', 'pret_vechi'];
            localParams.type = 'update';

            for (const e in localParams) {
                if (e == 'type') continue;

                if (updateFields.indexOf(e) == -1) {
                    delete localParams.type;
                    localParams = this.cleanArray(localParams);
                    break;
                }
            }

            if ('undefined' == typeof localParams.type) return params;

            localParams.products_model = params['products_model'];

            return localParams;
        },
        cleanArray(array) {
            for (let i = 0; i < array.length; i++) {
                if (array[i] == null) {
                    array.splice(i, 1);
                    i--;
                }
            }

            return array;
        },
        validateData() {
            this.inputProblems = [];
            if (
                !(
                    typeof this.product.category !== 'undefined' &&
                    this.product.category !== '' &&
                    this.product.category !== 0
                )
            ) {
                this.inputProblems.push(this.$t('products.product.error-category'));
            }
            if (this.product.products_name == null || this.product.products_name.length < 5) {
                this.inputProblems.push(this.$t('products.product.error-name'));
            }
            // if (this.product.manufacturer == null || this.product.manufacturer.length < 2) {
            //     this.inputProblems.push(this.$t('products.product.error-brand'));
            // }
            if (this.product.products_model == null || this.product.products_model.length < 3) {
                this.inputProblems.push(this.$t('products.product.error-model'));
            }
            if (this.product.pretf < 1 || this.product.pretf.length < 1) {
                this.inputProblems.push(this.$t('products.product.error-price'));
            }
            // if (this.product.stoc < 1 || this.product.stoc.length < 1) this.inputProblems.push(this.$t('products.product.error-stock'))
            if (isNaN(this.product.stoc) || this.product.stoc.length < 1) {
                this.inputProblems.push(this.$t('products.pendingproduct.error-stock-2'));
            }

            let familyOk = false;
            let emptyFamilyCharacts = 0;

            let familyCharactsEmpty = false;
            for (const i in this.familyCharacts) {
                if (typeof this.familyCharacts[i].charact !== 'undefined' && this.familyCharacts[i].charact !== '') {
                    continue;
                } else emptyFamilyCharacts++;
            }
            if (this.familyCharacts.length === emptyFamilyCharacts || this.familyCharacts.length === 0) {
                familyCharactsEmpty = true;
            }

            if (this.chosenFamily !== '') {
                // params.family_id = this.product.family_id
                // params.family_name = this.product.family_name
                this.product.family_id = this.chosenFamily.match(/\((\d+)\)/);
                this.product.family_id = this.product.family_id[1];

                this.product.family_name = this.chosenFamily.match(/\)\s(.*)$/);
                this.product.family_name = this.product.family_name[1];
            }

            switch (true) {
                case this.product.family_id != null &&
                    this.product.family_id !== '' &&
                    (this.product.family_name == null ||
                        this.product.family_name === '' ||
                        this.familyCharacts.length < 1 ||
                        familyCharactsEmpty):
                    this.inputProblems.push(this.$t('products.product.error-family'));
                    break;
                case this.product.family_name != null &&
                    this.product.family_name !== '' &&
                    (this.product.family_id == null ||
                        this.product.family_id === '' ||
                        this.familyCharacts.length < 1 ||
                        familyCharactsEmpty):
                    this.inputProblems.push(this.$t('products.product.error-family-2'));
                    break;

                case !familyCharactsEmpty &&
                    (this.product.family_name == null ||
                        this.product.family_name === '' ||
                        this.product.family_id == null ||
                        this.product.family_id === ''):
                    this.inputProblems.push(this.$t('products.product.error-family-3'));

                default:
                    familyOk = true;
            }
            if (familyOk) {
                for (const i in this.familyCharacts) {
                    const currentFamilyCharact = this.familyCharacts[i];
                    if (currentFamilyCharact.charact == null || currentFamilyCharact.charact === '') {
                        this.familyCharacts.splice(i, 1);
                        continue;
                    }

                    let charName = this.allCharacteristics.find(
                        (c) => parseInt(c.charact_id) === parseInt(currentFamilyCharact.charact)
                    );
                    if (charName == null) {
                        this.familyCharacts.splice(i, 1);
                        continue;
                    }
                    charName = charName.charact_name;
                    if (
                        !has(this.product.charact, charName) ||
                        Object.keys(this.product.charact[charName]).length < 1
                    ) {
                        this.inputProblems.push(
                            this.$t('publish.add.error-charact') +
                                ' <strong>' +
                                charName +
                                '</strong> ' +
                                this.$t('publish.add.error-charact-2')
                        );
                    }
                }
            }

            // Disable required characts
            // for (const x in this.requiredCharacteristics) {
            //     if (
            //         isUndefined(this.product.charact[this.requiredCharacteristics[x]]) ||
            //         isEmpty(this.product.charact[this.requiredCharacteristics[x]]) ||
            //         (Array.isArray(this.product.charact[this.requiredCharacteristics[x]]) &&
            //             this.product.charact[this.requiredCharacteristics[x]].filter((i) => i.length > 0).length < 1)
            //     ) {
            //         this.inputProblems.push(
            //             this.$t('products.product.charact-single') +
            //                 ' <strong>' +
            //                 this.requiredCharacteristics[x] +
            //                 '</strong> ' +
            //                 this.$t('products.product.error-charact-3')
            //         );
            //     }
            // }
            // if (this.product.products_description < 5) {
            // 	this.inputProblems.push(
            // 		this.$t('products.product.error-desc'))
            // }
            if (this.product.products_image === '' || isNull(this.product.products_image)) {
                this.inputProblems.push(this.$t('products.product.error-main-image'));
            }
            // let mainImage = document.getElementById('mainImageId').querySelector('.el-upload__input').files
            // if (mainImage.length < 1) this.inputProblems.push('Imagine principala neselectata sau invalida(doar imagini PNG/JPG/GIF)')
            if (this.inputProblems.length > 0) {
                this.sbMsg({
                    type: 'error',
                    message: this.$t('products.product.error-general-save'),
                });
                document.getElementById('problemsContainer').scrollIntoView();

                return false;
            }

            return true;
        },
        getCategCharacts(val) {
            if (typeof val === 'undefined') return false;
            getCategCharacts({
                categ_id: val,
            })
                .then((res) => {
                    this.requiredCharacteristics = [];
                    this.allCharacteristics = [];
                    this.allCharacteristics = Object.values(res.message).sort((a, b) => {
                        return b.priority - a.priority;
                    });
                    // Adding the required cahracteristics to array
                    for (const x in this.allCharacteristics) {
                        this.allCharacteristics[x].count = 1;
                        if (!has(this.product.charact, this.allCharacteristics[x].charact_name)) {
                            this.product.charact[this.allCharacteristics[x].charact_name] = {};
                        } else {
                            this.allCharacteristics[x].count =
                                this.product.charact[this.allCharacteristics[x].charact_name].length || 1;
                            for (const y in this.product.charact[this.allCharacteristics[x].charact_name]) {
                                const inc = parseInt(y) + 1;
                                this.allCharacteristics[x]['selected_' + inc] =
                                    this.product.charact[this.allCharacteristics[x].charact_name][y];
                            }
                        }
                        if (this.allCharacteristics[x].priority > charactPriority()) {
                            this.requiredCharacteristics.push(this.allCharacteristics[x].charact_name);
                        }
                    }
                })
                .then(() => {
                    return this.getFamilies();
                })
                .then(() => {
                    if (this.product.family_id != null && !isNaN(parseInt(this.product.family_id))) {
                        const productFamily = this.families.find(
                            (fam) => parseInt(fam.id) === parseInt(this.product.family_id)
                        );
                        if (productFamily == null) {
                            this.sbMsg({
                                type: 'warn',
                                message:
                                    'Familia asociata acestui produs nu a fost gasita. Selectati sau creati o noua familie.',
                            });
                            return false;
                        }
                        this.familyCharacts = productFamily.characteristics;
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getCategCharacts',
                            params: {
                                categ_id: val,
                            },
                        },
                    });
                    debugPromise('getCategCharacts', e);
                });
        },
        setPlaceholder(priority) {
            priority = parseInt(priority);
            switch (true) {
                // case priority > charactPriority():
                //     return this.$t('products.product.required-charact');
                //     break;
                default:
                    return this.$t('products.product.optional-charact');
            }
        },
        checkCharactPriority(charId, priority) {
            priority = parseInt(priority);
            switch (true) {
                // case priority > 19:
                //     return {
                //         'is-danger': true
                //     };
                //     break;
                default:
                    return {
                        'is-success': false,
                    };
            }
        },
        setCharactVal(index, val, charact) {
            if (val === '') delete this.product.charact[charact.charact_name][index - 1];
            else this.product.charact[charact.charact_name][index - 1] = val;
        },
        openModal(charactId, restriction) {
            this.modalCharactId = charactId;
            this.modalCharactRestriction = restriction;
            this.dialogVisible = true;
        },
        addNewCharactVal() {
            if (this.newValueInput.length < 1) return false;
            const vm = this;
            this.allCharacteristics = this.allCharacteristics.map((char) => {
                if (parseInt(char.charact_id) === vm.modalCharactId) {
                    char.charact_value.push(vm.newValueInput);
                }
                return char;
            });
            this.dialogVisible = false;
            this.modalCharactId = '';
            this.modalCharactRestriction = '';
            this.newValueInput = '';
        },
        removeMainImage() {
            document.getElementById('mainImageId').querySelector('.el-upload__input').value = '';
        },
        setPrimaryImage(index) {
            const tmp = this.product.products_image;
            this.product.products_image = this.secondaryImages[index]['url'];
            this.secondaryImages[index]['url'] = tmp;
        },
        async getProductDetails(callback) {
            const params = {
                filters: {
                    products_model: [this.productsModel],
                },
                start: 0,
                limit: 1000,
                forceCount: false,
            };
            if (this.vendorExtId !== '') {
                params.filters.vendor_ext_id = [this.vendorExtId];
            }
            getProducts(params)
                .then(async (res) => {
                    if (
                        res == null ||
                        res.message.length == 0 ||
                        res.message.count < 1 ||
                        res.message.data.length < 1
                    ) {
                        this.sbMsg({
                            type: 'warn',
                            message: this.$t('products.product.error-get-product') + '..',
                        });
                        this.$router.push('/produse/lista').catch(() => {});
                        return false;
                    }

                    if (res.message.data.length > 1) {
                        if (this.vendorExtId === '') {
                            this.sbMsg({
                                type: 'warn',
                                message: this.$t('products.product.error-model-url'),
                            });
                            this.$router
                                .push({
                                    name: 'Lista produse',
                                })
                                .catch(() => {});
                        }
                    }

                    this.product = res.message.data[0];
                    this.product.status = String(this.product.status);
                    this.product.products_description = atob(this.product.products_description);
                    this.product.pretf = parseFloat(this.product.pretf).toFixed(2);
                    this.product.pret_vechi = parseFloat(this.product.pret_vechi).toFixed(2);
                    if (has(this.product, 'family_id') && parseInt(this.product.family_id) === 0) {
                        this.product.family_id = '';
                    }
                    if (
                        has(this.product, 'main_image') &&
                        !isNull(this.product.main_image) &&
                        this.product.main_image !== ''
                    ) {
                        this.$set(this.product, 'products_image', this.product.main_image);
                        // 'products_image', this.product.main_image)
                    } else this.$set(this.product, 'products_image', '');
                    const hasSecondaryImages = has(this.product, 'secondary_images');
                    if (hasSecondaryImages) {
                        // let imgCnt = 0
                        for (let i = 0; i < 5; i++) {
                            if (typeof this.product.secondary_images[i] !== 'undefined') {
                                this.secondaryImages.push(this.product.secondary_images[i]);
                            } else {
                                this.secondaryImages.push({
                                    id: 0,
                                    url: '',
                                });
                            }
                        }
                    }
                    // for (let i = 0; i < 5; i++) {
                    // 	if (hasSecondaryImages && typeof this.product.secondary_images[i] !== 'undefined') this.secondaryImages[i] =
                    // 		this.secondaryImagesPrefix + this.product.secondary_images[i]
                    // 	else this.secondaryImages.push('')
                    // }
                    if ('undefined' != typeof callback) callback();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getProducts',
                            params,
                        },
                    });
                    debugPromise('getProducts', e);
                });
        },
        clone(obj) {
            return JSON.parse(JSON.stringify(obj));
        },

        addFamilyCharact() {
            this.familyCharacts.push({
                charact: '',
                mode: 1,
            });
        },
        removeFamilyCharact(index) {
            this.familyCharacts.splice(index, 1);
        },
        replaceImage(data) {
            const params = setParams({});
            params.append('image', data.raw);
            ajaxUploadImage(params)
                .then((res) => {
                    if (res == null) return false;
                    this.$set(this.secondaryImages, this.idSecondaryImages, {
                        id: this.secondaryImages[this.idSecondaryImages].id,
                        url: res.message[0],
                    });
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'replaceImage',
                        },
                    });
                    console.log(e);
                });
        },
        replacePrincipalImage(data) {
            const params = setParams({});
            params.append('image', data.raw);
            ajaxUploadImage(params)
                .then((res) => {
                    this.product.products_image = res.message[0];
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'replacePrincipalImage',
                        },
                    });
                    console.log(e);
                });
        },
        setID(index) {
            this.idSecondaryImages = index;
        },
        async categorySelected(categ) {
            if (typeof categ.categories_id !== 'undefined') {
                this.product.category = categ.categories_id;
                if (parseInt(categ.categories_id) !== 0) {
                    this.getCategCharacts(categ.categories_id);
                } else {
                    this.allCharacteristics = [];
                    this.requiredCharacteristics = [];
                }
            }
        },
        getFamilies() {
            return new Promise((resolve, reject) => {
                getProductsFamilyForCategory({
                    categId: this.product.categories_id,
                })
                    .then((res) => {
                        this.families = res.message.families;
                        resolve();
                    })
                    .catch((e) => {
                        this.$reportToSentry(e, {
                            extra: {
                                fn: 'getProductsFamilyForCategory',
                                params: {
                                    categId: this.product.categories_id,
                                },
                            },
                        });
                        resolve();
                    });
            });
        },
        onFamilySelect(id) {
            const selectedFamily = this.families.find((family) => parseInt(family.id) === parseInt(id));
            if (selectedFamily == null) {
                this.sbMsg({
                    type: 'warn',
                    message: 'Nu am gasit familia selectata.',
                });
                return false;
            }
            this.product.family_name = selectedFamily.name;
            if (selectedFamily.characteristics.length > 0) {
                this.familyCharacts = selectedFamily.characteristics.map((c) => {
                    return {
                        charact: parseInt(c.charact),
                        mode: parseInt(c.mode),
                    };
                });
            }
        },
        addNewFamily() {
            window.open('/produse/familii', '_blank');
        },
        removeProductFamily() {
            this.product.family_id = '';
            this.product.family_name = '';
        },
    },
    watch: {
        'product.family_id'(val) {
            if (val === '' || val === 0) {
                this.familyCharacts = [];
                return false;
            }
            const selectedFamily = this.families.find((family) => parseInt(family.id) === parseInt(val));
            if (selectedFamily == null) return false;
            this.familyCharacts = selectedFamily.characteristics;
        },
    },
    computed: {
        mainImageLink() {
            return has(this.product, 'products_image') ? this.product.products_image : '';
        },
        familyTypeOptions() {
            if (this.allCharacteristics.length < 1) return [];
            return this.allCharacteristics
                .filter((item) => {
                    return (
                        typeof item.charact_id !== 'undefined' &&
                        item.charact_id !== '' &&
                        typeof item.charact_name !== 'undefined' &&
                        item.charact_name !== ''
                    );
                })
                .map((item) => {
                    return {
                        value: parseInt(item.charact_id),
                        label: item.charact_name,
                    };
                });
        },
        familyCharactsComputed() {
            return this.familyCharacts.length;
        },
        getSecondaryImages() {
            return this.secondaryImages;
        },
        characterLimits() {
            return this.$store.state.app.charactersLimit;
        },
    },
    mounted() {
        try {
            this.productsModel = atob(this.pid);
        } catch (e) {
            this.sbMsg({
                type: 'warn',
                message: this.$t('products.product.error-model-url'),
            });
            this.$router
                .push({
                    name: 'Lista produse',
                })
                .catch(() => {});
            return false;
        }
        if (this.vendorId != null) {
            try {
                this.vendorExtId = atob(this.vendorId);
            } catch (err) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('products.product.error-vendor-url'),
                });
                this.$router
                    .push({
                        name: 'Lista produse',
                    })
                    .catch(() => {});
                return false;
            }
        }
        // this.getCategories()
        if (this.productsModel) {
            this.getProductDetails(() => {
                this.oldValues = this.clone(this.product);
            });
        }
    },
    beforeDestroy() {
        EventBus.$off();
    },
};
</script>
<style rel="stylesheet/scss" lang="scss">
@import './src/styles/modules/produse.scss';
</style>
<style>
.el-upload {
    width: 100%;
}
.delinput input.el-input__inner {
    text-align: left !important;
}
</style>
<style scoped>
.delinput {
    width: 100%;
}
</style>
